import React from "react";
import App from "next/app";
import { AppProvider } from "@shopify/polaris";
import "@shopify/polaris/build/esm/styles.css";
import { AuthProvider, useAuthContext, useGraphQLClient } from "../contexts/auth.context";
import { I18nContext, I18nManager } from "@shopify/react-i18n";
import { ApplicationProvider } from "../contexts/application.context";
import RouteGuard from "../components/router/route-guard";
import "../styles/polaris-overrides.scss";
import { ApolloProvider } from "@apollo/client";
import { useRouter } from "next/router";
import Script from "next/script"

const locale = "fr";
const i18nManager = new I18nManager({
  locale,
  onError: (error) => { },
});

function HappyColisAppProvider(props: any) {
  const client = useGraphQLClient();
  const Component = props.Component;

  return (
    <ApolloProvider client={client}>
      <ApplicationProvider organization={props.subdomain}>
        <RouteGuard>
          <Component {...props} />
        </RouteGuard>
      </ApplicationProvider>
    </ApolloProvider>
  );
}

class HappyColisApp extends App {
  render() {
    const { Component, pageProps } = this.props;
    return (
      <I18nContext.Provider value={i18nManager}>
        <AppProvider
          i18n={{
            Polaris: {
              Avatar: {
                label: "Avatar",
                labelWithInitials: "Avatar with initials {initials}",
              },
              ContextualSaveBar: {
                save: "Save",
                discard: "Discard",
              },
              TextField: {
                characterCount: "{count} characters",
              },
              TopBar: {
                toggleMenuLabel: "Toggle menu",

                SearchField: {
                  clearButtonLabel: "Clear",
                  search: "Search",
                },
              },
              Modal: {
                iFrameTitle: "body markup",
              },
              Frame: {
                skipToContent: "Skip to content",
                navigationLabel: "Navigation",
                Navigation: {
                  closeMobileNavigationLabel: "Close navigation",
                },
              },
              Filters: {
                moreFilters: "Filtres",
                done: "Fermer",
                clearAllFilters: "Tout effacer",
              },
              ResourceList: {
                BulkActions: {
                  actionsActivatorLabel: "Modifier les éléments sélectionés",
                },
              },
              IndexTable: {
                emptySearchTitle: "{resourceNamePlural} introuvables",
                emptySearchDescription:
                  "Essayer de changer les filtres ou le terme de recherche",
                onboardingBadgeText: "Nouveau",
                resourceLoadingAccessibilityLabel:
                  "Chargement de {resourceNamePlural} en cours...",
                selectAllLabel:
                  "Sélectionner la totalité des {resourceNamePlural}",
                selected: "{selectedItemsCount} sélectionné(s)",
                undo: "Annuler",
                selectAllItems:
                  "Sélectionner la totalité des {itemsLength}+ {resourceNamePlural}",
                selectItem: "Sélectionner {resourceName}",
                selectButtonText: "Sélectionner",
              },
              DatePicker: {
                months: {
                  january: "Janvier",
                  february: "Février",
                  march: "Mars",
                  april: "Avril",
                  may: "Mai",
                  june: "Juin",
                  july: "Juillet",
                  august: "Aôut",
                  september: "Septembre",
                  october: "Octobre",
                  november: "Novembre",
                  december: "Décembre",
                },
              },
            },
          }}
        >
          <div suppressHydrationWarning={true}>
            {process.browser && (
              <AuthProvider>
                <HappyColisAppProvider Component={Component} {...pageProps} />
              </AuthProvider>
            )}
          </div>
        </AppProvider>
        <Script id="ze-snippet" type="text/javascript" src="https://static.zdassets.com/ekr/snippet.js?key=63710dc5-00ce-4779-b57f-bdcd13bf9869" strategy="beforeInteractive" />
      </I18nContext.Provider>
    );
  }
}

export default HappyColisApp;

HappyColisApp.getInitialProps = async (context) => {
  const { organization } = context.ctx.query;
  let subdomain = context.ctx.req?.headers.host.split(".")[0];
  if (subdomain?.includes("localhost")) {
    subdomain = (organization || "app") as string;
  }

  return {
    pageProps: {
      subdomain: subdomain === "app" ? undefined : subdomain,
    },
  };
};
