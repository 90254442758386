import React, {
  Dispatch,
  createContext,
  useContext,
  useReducer,
} from "react";
import { OrganizationFragment } from "../queries/__generated__/OrganizationFragment";

type ApplicationContextType = {
  organizationId?: string;
  organizationName?: string;
  organizationSlug?: string;
  memberships?: OrganizationFragment[];
  locale?: string;
  language?: string;
};

export const initialApplicationState: ApplicationContextType = {
  locale: "fr-FR",
  language: "fr",
};

type ApplicationDispatchContextType = Dispatch<any>;
type ApplicationReducerActionType = {
  type: string;
  payload?: ApplicationContextType;
};

export const ApplicationContext = createContext<ApplicationContextType>(
  initialApplicationState
);
export const ApplicationDispatchContext =
  createContext<ApplicationDispatchContextType>(() => null);

export const ApplicationReducer = (
  initialState: ApplicationContextType,
  action: ApplicationReducerActionType
): ApplicationContextType => {
  let newState;
  switch (action.type) {
    case "ORGANIZATION_SELECTED":
      newState = {
        ...initialState,
        organizationId: action.payload.organizationId,
        organizationName: action.payload.organizationName,
        organizationSlug: action.payload.organizationSlug,
      };
      break;
    case "MEMBERSHIPS_LOADED":
      newState = {
        ...initialState,
        memberships: action.payload.memberships,
      };
      break;
    case "LOCALE_SELECTED":
      newState = {
        ...initialState,
        locale: action.payload.locale
      }
      break;
    case "LANGUAGE_SELECTED":
      newState = {
        ...initialState,
        language: action.payload.language
      }
      break;

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }

  localStorage.setItem("applicationContext", JSON.stringify(newState));
  return newState;
};

type ApplicationProviderType = {
  children: React.ReactNode;
  organization?: string;
};

export const ApplicationProvider = (type: ApplicationProviderType) => {
  const { children } = type;
  const currentState = localStorage.getItem("applicationContext");
  const state = {
    ...initialApplicationState,
    ...JSON.parse(currentState || "{}"),
  } as ApplicationContextType;
  const [application, dispatch] = useReducer(ApplicationReducer, state);

  return (
    <ApplicationContext.Provider value={application}>
      <ApplicationDispatchContext.Provider value={dispatch}>
        {children}
      </ApplicationDispatchContext.Provider>
    </ApplicationContext.Provider>
  );
};

export const useApplicationContext = () => {
  const context = useContext(ApplicationContext);

  if (context === undefined) {
    throw new Error(
      "useApplicationContext must be used within an ApplicationProvider"
    );
  }

  return context;
};

export const useApplicationDispatchContext = () => {
  const context = useContext(ApplicationDispatchContext);

  if (context === undefined) {
    throw new Error(
      "useApplicationContext must be used within an ApplicationProvider"
    );
  }

  return context;
};
