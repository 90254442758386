import React, {
  Dispatch,
  createContext,
  useContext,
  useReducer,
  useCallback,
  useMemo,
} from "react";
import * as jwt from "jsonwebtoken";
import { useRouter } from "next/router";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { ErrorHandler, onError } from "@apollo/client/link/error";

export interface UserInterface {
  id: string;
  email: string;
  username: string;
  scopes?: string[];
}

export type AuthContextType = {
  isLoggedIn: boolean | undefined;
  user: UserInterface | undefined;
  accessToken: string | undefined;
  refreshToken: string | undefined;
};

export const initialAuthState: AuthContextType = {
  isLoggedIn: false,
  user: undefined,
  accessToken: undefined,
  refreshToken: undefined,
};

export type AuthDispatchContextType = Dispatch<any>;
export type AuthReducerActionType = {
  type: string;
  payload: {
    isLoggedIn?: boolean;
    user?: UserInterface;
    accessToken?: string;
    refreshToken?: string;
  };
};

export const AuthContext = createContext<AuthContextType>(initialAuthState);
export const AuthDispatchContext = createContext<AuthDispatchContextType>(
  () => null
);

export const AuthReducer = (
  initialState: AuthContextType,
  action: AuthReducerActionType
): AuthContextType => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      localStorage.setItem("accessToken", action.payload.accessToken as string);
      action.payload.refreshToken &&
        localStorage.setItem(
          "refreshToken",
          action.payload.refreshToken as string
        );
      return {
        ...initialState,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        isLoggedIn: true,
      };
    case "LOGIN_ERROR":
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("user");
      return {
        ...initialState,
        accessToken: undefined,
        refreshToken: undefined,
        isLoggedIn: false,
        user: undefined,
      };
    case "LOGOUT":
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("user");
      return {
        ...initialState,
        accessToken: undefined,
        refreshToken: undefined,
        isLoggedIn: false,
        user: undefined,
      };
    case "USER":
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      return {
        ...initialState,
        user: action.payload.user,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

type AuthProviderType = {
  children: React.ReactNode;
};

export const AuthProvider = (type: AuthProviderType) => {
  const { children } = type;
  const state: AuthContextType = { ...initialAuthState };
  if (typeof window !== "undefined") {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const userData = JSON.parse(localStorage.getItem("user"));
    if (accessToken && accessToken !== "undefined") {
      const { exp } = jwt.decode(accessToken) as any;
      if (Date.now() > exp * 1000) {
        localStorage.removeItem("accessToken");
      } else {
        state.accessToken = accessToken;
        state.isLoggedIn = true;
        state.user = userData;
      }
    }

    state.refreshToken = refreshToken || undefined;
  }

  const [user, dispatch] = useReducer(AuthReducer, state);
  return (
    <AuthContext.Provider value={user}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }

  return context;
};

export const useAuthDispatchContext = () => {
  const context = useContext(AuthDispatchContext);

  if (context === undefined) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }

  return context;
};

export const unauthorizedInterceptor: ErrorHandler = ({
  graphQLErrors,
  networkError,
  operation,
  forward,
}) => {
  if (graphQLErrors) {
    if (graphQLErrors[0].extensions.code === "UNAUTHENTICATED") {
      // TODO CALL API TO REFRESH THE ACCESS TOKEN

      const loginData = {
        refresh_token: localStorage.getItem("refreshToken"),
        grant_type: "refresh_token",
        client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
      };

      fetch(`${process.env.NEXT_PUBLIC_API_URL}/oauth2/token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      }).then(async (result) => {
        const dispatch = useAuthDispatchContext();
        const accessToken = await result.json();

        dispatch({
          type: "LOGIN_SUCCESS",
          payload: {
            accessToken: accessToken.access_token,
            refreshToken: accessToken.refresh_token,
          },
        });

        return forward(operation);
      });
    }
  }
};

export const useGraphQLClient = () => {
  const { accessToken, refreshToken } = useAuthContext();
  const dispatch = useAuthDispatchContext();
  const router = useRouter();

  const getNewToken = useCallback(async () => {
    const loginData = {
      refresh_token: refreshToken,
      grant_type: "refresh_token",
      client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
    };

    return await fetch(`${process.env.NEXT_PUBLIC_API_URL}/oauth2/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    });
  }, [refreshToken]);

  const userLoggedInFetch = useCallback(() => {
    return async (
      uri: RequestInfo,
      options?: RequestInit
    ): Promise<Response> => {
      const accesstTokenData = accessToken
        ? (jwt.decode(accessToken) as any)
        : undefined;
      const now = Date.now();
      const accessTokenValid =
        accesstTokenData && now < accesstTokenData.exp * 1000;

      let currentToken = accessToken;
      if (!accessTokenValid && refreshToken) {
        try {
          const newToken = await getNewToken();

          if (newToken.status === 401) {
            dispatch({ type: "LOGOUT" });
            router.push({
              pathname: "/login",
              query: { redirectUri: router.asPath },
            });
            return;
          }

          const accessToken = await newToken.json();
          dispatch({
            type: "LOGIN_SUCCESS",
            payload: {
              accessToken: accessToken.access_token,
              refreshToken: accessToken.refresh_token,
            },
          });

          currentToken = accessToken.access_token;
        } catch (e) {
          dispatch({ type: "LOGOUT" });
          router.push({
            pathname: "/login",
            query: { redirectUri: router.asPath },
          });

          return;
        }
      }

      const response = await fetch(uri, {
        ...options,
        headers: {
          ...(options?.headers || {}),
          ...(currentToken ? { authorization: `Bearer ${currentToken}` } : {}),
        },
      });

      return response;
    };
  }, [accessToken, dispatch, getNewToken, refreshToken, router]);

  const unauthorizedClient = useCallback(
    () =>
      new ApolloClient({
        uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
        cache: new InMemoryCache(),
        link: ApolloLink.from([
          onError(unauthorizedInterceptor),
          new HttpLink({
            uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
            fetch: userLoggedInFetch(),
          }),
        ]),
      }),
    [userLoggedInFetch]
  );

  const authorizedClient = useCallback(
    () =>
      new ApolloClient({
        uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
        cache: new InMemoryCache(),
        link: ApolloLink.from([
          onError(unauthorizedInterceptor),
          new HttpLink({
            uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
            fetch: userLoggedInFetch(),
          }),
        ]),
      }),
    [userLoggedInFetch]
  );


  return accessToken ? authorizedClient() : unauthorizedClient();
};

export const useUser = () => {
  const { user, accessToken } = useAuthContext();
  const dispatch = useAuthDispatchContext();
  const router = useRouter();

  const accesstTokenData = jwt.decode(accessToken || "") as any;
  const now = Date.now();
  if (!accesstTokenData || now < accesstTokenData.exp * 1000) {
    dispatch({ type: "LOGOUT" });
    router.push({
      pathname: "/login",
      query: {
        redirectUri: router.asPath,
      },
    });
    return null;
  }

  return user;
};
