import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import * as jwt from "jsonwebtoken";
import {
  useAuthContext,
  useAuthDispatchContext,
} from "../../contexts/auth.context";

function RouteGuard({ children }) {
  const router = useRouter();
  const [authorized, setAuthorized] = useState(false);
  const { accessToken, refreshToken } = useAuthContext();

  useEffect(() => {
    // on initial load - run auth check
    authCheck(router.asPath);

    // on route change start - hide page content by setting authorized to false
    const hideContent = () => setAuthorized(false);
    router.events.on("routeChangeStart", hideContent);

    // on route change complete - run auth check
    router.events.on("routeChangeComplete", authCheck);

    // unsubscribe from events in useEffect return function
    return () => {
      router.events.off("routeChangeStart", hideContent);
      router.events.off("routeChangeComplete", authCheck);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, router]);

  function authCheck(url) {
    // redirect to login page if accessing a private page and not logged in
    const publicPaths = [
      "/login",
      "/reset-password",
      "/password-lost",
      "/confirm-account",
      "/confirm-invitation",
    ];

    const path = url.split("?")[0];

    const accesstTokenData = accessToken
      ? (jwt.decode(accessToken) as any)
      : undefined;
    const now = Date.now();
    const accessTokenValid =
      accesstTokenData && now < accesstTokenData.exp * 1000;
    if (!publicPaths.includes(path) && !accessTokenValid && !refreshToken) {
      setAuthorized(false);
      router.push({
        pathname: "/login",
        query: { redirectUri: router.asPath },
      });
    } else {
      setAuthorized(true);
    }
  }

  return authorized && children;
}
export default RouteGuard;
